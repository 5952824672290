import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import GridContainer from "@jumbo/components/GridContainer";
import { useDispatch } from "react-redux";
import { getUserActivities } from "redux/actions/UserActivities";
import Beneficio from "./Beneficio";
import { NOTICIAS_VER } from "services/permissionProvider/PermissionsCatalog";
import Forbidden from "@jumbo/components/Forbidden";
import Restricted from "services/permissionProvider/Restricted";
import { useHasPrivilege } from "routes/checkPermission";
import {
  Cake,
  FitnessCenter,
  Language,
  LocalGroceryStore,
  School,
  Today,
  HomeWork,
  Favorite,
  EmojiPeople,
  Weekend,
  LocalHospital,
  Create,
  ChildFriendly
} from "@material-ui/icons";

const Benefits = () => {
  const canDispatch = useHasPrivilege(NOTICIAS_VER);
  const dispatch = useDispatch();

  const iconStyle = { fontSize: "1rem", margin: "0", padding: "0" };

  useEffect(() => {
    if (canDispatch) {
      dispatch(getUserActivities([], "", () => {}));
    }
  }, [dispatch, canDispatch]);

  return (
    <Restricted to={NOTICIAS_VER} fallback={Forbidden}>
      <GridContainer spacing={5}>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Club de Beneficios"
            icon={<LocalGroceryStore />}
            image="/images/club-beneficios.png"
            beneficio="Club de Beneficios"
            texto={
              <>
                <p>
                  CDT te habilita a sumarte a un e-commerce donde podés acceder
                  a primeras marcas de Unilever con descuentos especiales.
                </p>
                <br />
                <p>
                  Marcas como Dove, Skip, Ala, Knorr, Cif, Vim, Hellmann`s,
                  Rexona, Axe, Knorr, Milka, Oreo, Heineken ¡y más!
                </p>
                <br />
                <p>Envíos a todo el país (salvo Tierra del Fuego).</p>
                <br />
                <p>
                  Para poder acceder al beneficio, ingresá al sitio{" "}
                  <a
                    href="https://clubdebeneficios.com/customer/account/create/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://clubdebeneficios.com/customer/account/create/
                  </a>
                  , creá tu cuenta (con email de CDT y contraseña a elección) y
                  registrate.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Bienestar y Salud"
            icon={<FitnessCenter style={iconStyle} />}
            image="/images/BienestarYSalud.png"
            beneficio="Bienestar y Salud"
            texto={
              <>
                <p>
                  Wellhub ofrece una variedad de opciones de{" "}
                  <strong>fitness y bienestar</strong> (online y presencial), y
                  de planes y precios para que puedas elegir las opciones que
                  mejor se adapten a vos.
                </p>
                <br />
                <p>
                  Para poder acceder al beneficio, registrate desde la app o
                  desde la{" "}
                  <a
                    href="https://www.gympass.com/sign-up/eligibility?client_id=09c955bc-c79c-48d8-bc61-3a9b34d4a92c&company_name=CDT&country_name=AR"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    web.
                  </a>
                  Ingresá con tu correo de CDT y elegí un plan al cual
                  suscribirte.
                </p>
                <br />
                <p>¡Podés añadir hasta 3 suscripciones familiares!</p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Viernes Flex"
            icon={<Today />}
            image="/images/viernes-flex.png"
            beneficio="Viernes Flex"
            texto={
              <>
                <p>
                  Se podrá realizar jornada reducida hasta un viernes por mes,
                  siempre y cuando la carga laboral y las características de las{" "}
                  <strong>tareas/proyecto/cliente</strong> lo permitan.
                </p>
                <br />
                <p>
                  El beneficio deberá ser solicitado con no más de una semana de
                  antelación, y hasta el martes previo el Responsable de Área
                  y/o Servicio deberá notificar a Capital Humano, quienes serán
                  las personas que gozarán del beneficio. <br />
                  Ese viernes se comienza la jornada entre las 08:00 hs y las
                  10:00 y finaliza entre las 14:00 y las 15:00 hs., cumpliendo
                  con una jornada de 5 horas de trabajo.
                </p>
                <br />
                <p>
                  <strong>Requisitos:</strong>
                </p>
                <ul>
                  <li>
                    Se deberá tener al menos tres meses de antigüedad en la
                    empresa.
                  </li>
                  <li>
                    Lo aprueba el Responsable de Área y/o Proyecto, cuando los
                    objetivos de trabajo estén cumplidos y el desempeño sea
                    satisfactorio.
                  </li>
                  <li>
                    Se podrá gozar del beneficio{" "}
                    <strong>hasta 1 vez al mes</strong>.
                  </li>
                </ul>
                <p>
                  <strong>
                    No se podrá gozar del beneficio en los siguientes casos:
                  </strong>
                </p>
                <ul>
                  <li>
                    Si esa semana o la anterior hubieron feriados/días no
                    laborables.
                  </li>
                  <li>
                    Habiendo gozado vacaciones u otro tipo de licencia la semana
                    anterior.
                  </li>
                  <li>
                    Habiendo cursado en esa misma semana licencia por estudio,
                    enfermedad u otro tipo de licencias.
                  </li>
                </ul>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Descuento académico"
            icon={<School style={iconStyle} />}
            image="/images/AcompañamientoAcademico.png"
            beneficio="Descuento académico"
            texto={
              <>
                <p>
                  En CDT, creemos que el desarrollo educativo de nuestros
                  colaboradores es clave para alcanzar el éxito en conjunto.
                </p>
                <br />
                <p>
                  Este beneficio busca potenciar el capital humano de CDT,
                  alineando tus habilidades y conocimientos, con las necesidades
                  y metas estratégicas de CDT, facilitándote el acceso a
                  universidades de primer nivel.
                </p>
                <br />
                <p>
                  Accedé en este{" "}
                  <a
                    href="https://cessi.org.ar/beneficios/?cat=38"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>enlace </strong>
                  </a>
                  a los descuentos en carreras de grado, posgrado y cursos en
                  distintas universidades a través de la CESSI (Cámara de la
                  Industria Argentina del Software), de la que es miembro CDT.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Clases de Inglés"
            icon={<Language />}
            image="/images/ingles.png"
            beneficio="Clases de Inglés"
            texto={
              <>
                <p>
                  En CDT te damos la posibilidad de formarte en idioma inglés.
                </p>
                <br />
                <p>Requisitos:</p>
                <ul>
                  <li>
                    Se deberá tener al menos tres meses de antigüedad en la
                    empresa.
                  </li>
                  <li>
                    Conformar un grupo con un mínimo de 2 personas del mismo
                    nivel.
                  </li>
                  <li>
                    Las clases deberán ser fuera del horario laboral (antes o
                    después de horario).
                  </li>
                  <li>
                    Cumplir con un mínimo de asistencia del 75% mensual y
                    aprobar los exámenes, caso contrario, CDT evaluará la
                    continuidad del beneficio.
                  </li>
                </ul>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Descuento en CUI"
            icon={<Language style={iconStyle} />}
            image="/images/cui1.png"
            beneficio="Descuento en Centro Universitario de Idiomas"
            texto={
              <>
                <p>
                  CDT te habilita a acceder al Centro Universitario de
                  Idiomas (inglés, alemán, italiano, francés y portugués), en
                  cursadas fuera de horario laboral.
                </p>
                <br />
                <p>
                  <strong>¿De que trata el beneficio?</strong>
                </p>
                <ul>
                  <li>
                    15% de descuento en cualquiera de los cursos ofrecidos
                    (bimestrales, trimestrales y cuatrimestrales).
                  </li>
                  <li>Más de 15 idiomas</li>
                  <li>Modalidad: Presencial o virtual</li>
                  <li>
                    Válido{" "}
                    <strong>para vos y tu grupo familiar primario.</strong>
                  </li>
                  <li>Financiación para cursos regulares de 2, 3 y 4 meses.</li>
                </ul>
                <br />
                <p>Contactate por mail a info@cuicorporate.com.</p>
                <p>
                  El código de descuento para acceder al beneficio es:{" "}
                  <strong>CDT</strong>
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Día de Cumpleaños"
            icon={<Cake />}
            image="/images/cumple.png"
            beneficio="Día de Cumpleaños"
            texto={
              <>
                <p>¡En CDT te acompañamos en tu día especial!</p>
                <p>
                  Si la fecha de tu cumpleaños{" "}
                  <strong>coincide con un día laboral</strong>, podrás disfrutar
                  ese día como uno libre.
                </p>
                <br />
                <p>¡Es nuestro regalo para que celebres a tu manera!</p>
                <br />
                <p>
                  <strong>Previamente,</strong> deberás coordinar con el
                  proyecto / equipo / cliente.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Capacitaciones"
            icon={<School style={iconStyle} />}
            image="/images/Capacitaciones.png"
            beneficio="Capacitaciones"
            texto={
              <>
                <p>
                  Descubrí oportunidades para expandir tus habilidades y
                  conocimientos, impulsando tu desarrollo con nuestras opciones
                  de formación especializada.
                </p>
                <br />
                <p>
                  Consultá con tu Gestor y/o Responsable las alternativas
                  disponibles.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Cobertura Médica"
            icon={<LocalHospital style={iconStyle} />}
            image="/images/CoberturaMedica.png"
            beneficio="Cobertura Médica de Primer Nivel"
            texto={
              <>
                <p>
                  El Plan Individual de Prepaga que ofrece CDT te permitirá
                  acceder a una cobertura médica completa y de primer nivel.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Día De Mudanza"
            icon={<Weekend style={iconStyle} />}
            image="/images/DiasPorMudanzas.png"
            beneficio="Día Por Mudanza"
            texto={
              <>
                <p>¡Facilitamos tu mudanza!</p>
                <p>
                  Te ofrecemos <strong>2 días hábiles corridos</strong> para que
                  puedas enfocarte en tu cambio de residencia y transitarlo de
                  la mejor forma posible.
                </p>
                <br />
                <p>
                  <strong>Previamente</strong> deberás coordinar con el proyecto
                  / equipo / cliente y firmar una nueva Declaración Jurada de
                  Domicilio, para acceder a este beneficio.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Voucher para Kit Escolar"
            icon={<Create style={iconStyle} />}
            image="/images/KitEscolar.png"
            beneficio="Voucher para Kit Escolar"
            texto={
              <>
                <p>
                  Al inicio de clases se hará entrega de un{" "}
                  <strong>voucher</strong> a los colaboradores que tengan hijos
                  en edad escolar, es decir a partir de{" "}
                  <strong>
                    jardín de infantes (excluye sala maternal y guardería)
                  </strong>{" "}
                  y hasta la <strong>finalización del secundario</strong> (18
                  años de edad inclusive).
                </p>
                <br />
                <p>El mismo, podrá ser canjeado por útiles escolares.</p>
                <br />
                <p>
                  <strong>Previamente</strong>, deberás presentar a Capital
                  Humano el <strong>certificado de alumno regular</strong> en el
                  mes de <strong>diciembre</strong>. En el caso de tratarse de
                  hijos que ingresan a jardín de infantes, se deberá presentar
                  la <strong>constancia de inscripción</strong>.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Licencia Por Paternidad"
            icon={<ChildFriendly style={iconStyle} />}
            image="/images/LicenciaPaternidad.png"
            beneficio="Licencia Extendida Por Paternidad"
            texto={
              <>
                <p>
                  Si bien la Ley de Contrato de Trabajo (LCT) establece 2 días,
                  en CDT entendemos la importancia de estar presentes en tus
                  momentos claves.
                </p>
                <br />
                <p>
                  Por eso, te acompañamos con una licencia extendida por
                  paternidad de <strong>7 días corridos</strong> para que puedas
                  disfrutar este momento especial!
                </p>
                <br />
                <p>
                  <strong>Antes de finalizar la licencia</strong>, deberás
                  presentar el certificado de nacimiento correspondiente.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Programa De Referidos"
            icon={<EmojiPeople style={iconStyle} />}
            image="/images/ProgramaReferidos.png"
            beneficio="Programa De Referidos"
            texto={
              <>
                <p>
                  Si conocés un colega / amigo que pueda estar interesado/a en
                  alguna de las vacantes vigentes, enviá por mail su Curriculum
                  Vitae (CV) y/o el perfil de LinkedIn actualizado
                  a referidos@cdt.com.ar para ser tenido en cuenta como posible
                  candidato a ocupar la vacante y participas por el premio que
                  consiste en una <strong>Gift Card</strong> cargada con un
                  monto en pesos.
                </p>
                <br />
                <p>
                  <strong>
                    Tené en cuenta lo siguiente en cuanto al mail:
                  </strong>
                </p>
                <ul>
                  <li>En el asunto:</li>
                </ul>
                <p>
                  Escribir “PROGRAMA DE REFERIDOS -” e indicar el nombre de la
                  búsqueda vigente para la cual estás postulando a tu referido
                  (por ejemplo: “Analista Desarrollador Java Ssr”)
                </p>
                <br />
                <ul>
                  <li>En el cuerpo:</li>
                </ul>
                <p>
                  Contanos qué relación tenés con la persona y por qué creés que
                  podría sumar en el equipo.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Regalo Por Casamiento"
            icon={<Favorite style={iconStyle} />}
            image="/images/ReconocimientoCasamiento.png"
            beneficio="Regalo Por Casamiento"
            texto={
              <>
                <p>En CDT, celebramos tus momentos especiales.</p>
                <p>
                  Como muestra de nuestro apoyo, te obsequiamos una{" "}
                  <strong>gift card</strong> para que la disfrutes según tus
                  preferencias.
                </p>
                <br />
                <p>
                  <strong>Previamente</strong> deberás presentar los
                  comprobantes correspondientes que acrediten este
                  acontecimiento.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Regalo Por Graduación"
            icon={<School style={iconStyle} />}
            image="/images/ReconocimientoGraduacion.png"
            beneficio="Regalo Por Graduación"
            texto={
              <>
                <p>
                  En CDT reconocemos tu dedicación y esfuerzo al graduarte de
                  una carrera universitaria o terciaria afín a la posición que
                  ocupes.
                </p>
                <br />
                <p>
                  Te obsequiamos, <strong>por única vez</strong>, una{" "}
                  <strong>gift card</strong> como muestra de nuestro apoyo. Para
                  su entrega deberás presentarte en las oficinas de CDT.
                </p>
                <br />
                <p>
                  <strong>Previamente</strong> deberás presentar la
                  constancia/copia de título en trámite
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Regalo Por Maternidad"
            icon={<ChildFriendly style={iconStyle} />}
            image="/images/ReconocimientoNacimiento.png"
            beneficio="Regalo Por Nacimiento/Adopción"
            texto={
              <>
                <p>En CDT, celebramos este momento tan especial.</p>
                <p>
                  Ya sea por nacimiento o adopción, te obsequiamos una{" "}
                  <strong>gift card</strong> para que la disfrutes en lo que más
                  desees.
                </p>
                <br />
                <p>
                  <strong>Previamente</strong> deberás presentar los
                  comprobantes correspondientes que acrediten este
                  acontecimiento.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Seguro De Vida"
            icon={<LocalHospital style={iconStyle} />}
            image="/images/SeguroDeVida.png"
            beneficio="Seguro De Vida Preferencial"
            texto={
              <>
                <p>
                  CDT otorga a sus colaboradores, adicional al Seguro de Vida
                  Obligatorio de LCT, un seguro de vida equivalente a{" "}
                  <strong>20 sueldos</strong> para el caso de muerte o
                  invalidez.
                </p>
                <br />
                <p>
                  Al momento de tu ingreso deberás completar formulario con la
                  designación de beneficiario/s.
                </p>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2} lg={2}>
          <Beneficio
            title="Trabajo Remoto"
            icon={<HomeWork style={iconStyle} />}
            image="/images/TrabajoRemoto.png"
            beneficio="Trabajo Remoto"
            texto={
              <>
                <p>
                  En CDT, ofrecemos diversas modalidades de Trabajo Remoto,
                  dependiendo de la naturaleza de tus tareas y el proyecto en el
                  que estés participando.
                </p>
                <br />
                <p>
                  Incluso, si necesitas trabajar desde{" "}
                  <strong>cualquier punto del país.</strong>
                </p>
                <br />
                <p>Sólo deberás asegurarte de:</p>
                <ul>
                  <li>Cumplir condiciones óptimas de conectividad.</li>
                  <li>
                    Dar aviso previamente en caso de necesitar trabajar de forma
                    remota desde otra locación que no sea tu domicilio
                    declarado.
                  </li>
                </ul>
                <br />
                <p>
                  Tené en cuenta que el trabajo remoto{" "}
                  <strong>puede ajustarse en cualquier momento</strong>, incluso
                  la asistencia presencial, según las necesidades del proyecto y
                  los requerimientos del cliente
                </p>
              </>
            }
          />
        </Grid>
      </GridContainer>
    </Restricted>
  );
};

export default Benefits;
